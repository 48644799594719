import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { GoogleMapsAPIProvider } from '@pledge-earth/web-components';
import type { ReactNode } from 'react';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { GlobalToastRegion } from '@pledge-earth/product-language';

import { ReduxSagaProvider } from '../store/provider';
import { reducer as settings } from '../store/settings/reducers';
import { Localization } from '../localization';

const reducer = combineReducers({
  settings,
});

const store = configureStore({
  reducer,
});

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_PUBLIC_ENDPOINT,
  cache: new InMemoryCache(),
});

export function ProviderImpact({ children }: { children: ReactNode }) {
  return (
    <ApolloProvider client={client}>
      <ReduxSagaProvider store={store}>
        <GoogleMapsAPIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_JAVASCRIPT_API_KEY || ''}>
          <Localization>
            {children}
            <GlobalToastRegion />
          </Localization>
        </GoogleMapsAPIProvider>
      </ReduxSagaProvider>
    </ApolloProvider>
  );
}
